import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";
import { MoralisProvider } from "react-moralis";

import { Loader } from "components/Loader";
import { MediaRunningProvider } from "context/MediaRunningContext";
import { AuthenticationProvider } from "features/auth/context/AuthContext";
import { CollectionDataProvider } from "features/collections/context/CollectionDataContext";
import { ProgressLoaderProvider } from "features/displayProgress/context/ProgressLoaderContext";
import { AnalyzedPictureProvider } from "features/mintNewNFTs/context/AnalyzedPictureContext";
import { NFTCreateDetailsProvider } from "features/mintNewNFTs/context/NFTCreateContext";
import { PutOnSaleDataProvider } from "features/mintNewNFTs/context/PutOnSaleDataContext";
import { NotificationProvider } from "features/notification/context/NotificationContext";
import { queryClient } from "lib/react-query-config";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";


const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID || "";
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL || "";
const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
    <AuthenticationProvider>
      <NFTCreateDetailsProvider>
        <CollectionDataProvider>
          <AnalyzedPictureProvider>
            <PutOnSaleDataProvider>
              <ProgressLoaderProvider>
                <NotificationProvider>
                  <MediaRunningProvider>
                    <QueryClientProvider client={queryClient}>
                      <Suspense fallback={<Loader />}>
                        <App />
                      </Suspense>
                      <ReactQueryDevtools initialIsOpen />
                    </QueryClientProvider>
                  </MediaRunningProvider>
                </NotificationProvider>
              </ProgressLoaderProvider>
            </PutOnSaleDataProvider>
          </AnalyzedPictureProvider>
        </CollectionDataProvider>
      </NFTCreateDetailsProvider>
    </AuthenticationProvider>
  </MoralisProvider>
);
serviceWorkerRegistration.register();
reportWebVitals();
