import React, { useContext, useEffect, useState } from 'react';
import { useMoralisCloudFunction, useMoralisSubscription } from 'react-moralis';

import { useAuthentication } from 'features/auth/context/AuthContext';
import { CONTEXTPROVIDERERROR } from 'utils/informatives';

const NotificationContext = React.createContext<any>('');

const NotificationProvider = ({ children }: any) => {
  const [notifyData, setNotifyData] = useState<any>();
  const { chainID: chainId, walletAddress: account } = useAuthentication();
  const { fetch: getPending } = useMoralisCloudFunction(
    'getPendingTran',
    {
      owner: account,
      chainID: chainId,
    },
    { autoFetch: false }
  );
  useMoralisSubscription(
    'MarketItem',
    (q) => q.equalTo('owner', account),
    [account, chainId],
    {
      onCreate: (data1) => getResponse(data1),
    }
  );

  const getResponse = (data) => {
    setNotifyData((prev) => {
      const prevData = prev?.length ? [...prev] : [];
      prevData.push({
        ItemName: data?.attributes?.ItemName,
        ItemDesc: data?.attributes?.ItemDesc,
        IPFSIMG: data?.attributes?.ipfs_img_url,
        confirmed: data?.attributes?.confirmed,
      });
      return prevData;
    });
  };
  useEffect(() => {
    if (chainId && account) {
      (async () => {
        const data = await getPending();
        setNotifyData(data);
      })();
    }
  }, [setNotifyData, getPending, chainId, account]);

  return (
    <NotificationContext.Provider
      value={{
        notifyData,
        setNotifyData,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

function useNotification() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR('NFTCreateDetail'));
  }
  return context;
}

export { NotificationProvider, useNotification };
