import { Web3ProviderType } from "features/auth/types/types.auth";

const storage = {
  getWeb3Provider: () => {
    return localStorage.getItem("providerType") as Web3ProviderType;
  },
  setWeb3Provider: (web3Provider: Web3ProviderType) => {
    localStorage.setItem("providerType", web3Provider);
  },
  getTheme: () => {
    return localStorage.getItem("theme") as string;
  },
  setTheme: (theme: string) => {
    localStorage.setItem("theme", theme);
  },
  setChainId: (chain: string) => {
    localStorage.setItem("chainId", chain);
  },
  clearLocalStorage: () => {
    localStorage.clear();
  },
};

export default storage;
