import React, { createContext, useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';

import { IAuthContext, Web3ProviderType } from 'features/auth/types/types.auth';
import { CONTEXTPROVIDERERROR } from 'utils/informatives';
import storage from 'utils/storage';

const AuthContext = createContext<IAuthContext | null>(null);

const AuthenticationProvider = ({ children }: any) => {
  const [web3provider, setWeb3Provider] = useState<Web3ProviderType>();
  const [chainID, setChainID] = useState<IAuthContext['chainID']>('');
  const [walletAddress, setWalletAddress] =
    useState<IAuthContext['walletAddress']>('');
  const [authenticated, setAuthenticated] =
    useState<IAuthContext['authenticated']>(false);
  const {
    isAuthenticated,
    chainId,
    account,
    isWeb3Enabled,
    isWeb3EnableLoading,
    enableWeb3,
    Moralis,
    user,
  } = useMoralis();

  const providerName: Web3ProviderType = storage.getWeb3Provider();

  useEffect(() => {
    storage.setChainId(chainId);
    setChainID(chainId);
    setAuthenticated(isAuthenticated);
    setWalletAddress(account);
  }, [isAuthenticated, chainId, account]);

  useEffect(() => {
    setWeb3Provider(providerName);
  }, [providerName]);

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
      enableWeb3({ provider: providerName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);
  useEffect(() => {
    if (isAuthenticated)
      Moralis.onAccountChanged(async (account) => {
        if (!user?.attributes?.accounts.includes(account)) {
          await Moralis.link(account);
        }
      });
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        web3provider,
        chainID,
        walletAddress,
        authenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuthentication() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR('MoralisDapp'));
  }
  return context;
}

export { AuthenticationProvider, useAuthentication };
