import { lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { useAuthentication } from "features/auth/context/AuthContext";

import ScrollToTop from "./ScrollToTop";
import { Page } from "./types";

const Footer = lazy(() => import("shared/Footer/Footer"));
const Page404 = lazy(() => import("features/misc/PageNotFound"));
const AuthorPage = lazy(() => import("features/displayNFTs/container/MintedItems"));
const SiteHeader = lazy(() => import("containers/SiteHeader"));
const NftDetailPage = lazy(() => import("features/nftDetail/container/NftDetailPage"));
const PageCollection = lazy(() => import("containers/PageCollection"));
const PageSearch = lazy(() => import("containers/PageSearch"));
const HomePage = lazy(() => import("features/misc/LandingPage"));
const CreateNFT = lazy(() => import("features/mintNewNFTs/container/Create"));
const NftMintingTypes = lazy(() => import("features/mintNewNFTs/container/NftMintingTypes"));

const PrivateRoute = ({ children }) => {
  const { authenticated: isAuthenticated } = useAuthentication();
  return isAuthenticated && localStorage.chainId !== undefined ? children : <Navigate to="/" />;
};

export const pages:Page[] = [
  { path: "/", component: <HomePage /> },
  { path: "/#", component: <HomePage /> },
  { path: "/nft-detailt", component: <NftDetailPage /> },
  { path: "/:chainId/:contractAddress/:tokenId", component: <NftDetailPage /> },
  { path: "/page-collection", component: <PageCollection /> },
  { path: "/page-search", component: <PageSearch /> },
  { path: "/profile", component: <AuthorPage /> },
  {
    path: "/create/free",
    component: (
      <PrivateRoute>
        <CreateNFT />
      </PrivateRoute>
    ),
  },
  {
    path: "/create/onChain",
    component: (
      <PrivateRoute>
        <CreateNFT />
      </PrivateRoute>
    ),
  },
  {
    path: "/create/types",
    component: (
      <PrivateRoute>
        <NftMintingTypes />
      </PrivateRoute>
    ),
  },
];

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component, path }) => {
          return <Route key={path} element={component} path={path} />;
        })}
        <Route element={<Page404 />} path="*" />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default RoutesComponent;
